<template>
  <div class="type-management-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="申请人单号">
            <el-input v-model="queryForm.advertisingCode" clearable></el-input>
          </el-form-item>

          <el-form-item label="申请人公司">
            <el-input v-model="queryForm.enterpriseName" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        label="申请单号"
        prop="advertisingCode"
        show-overflow-tooltip
      />
      <el-table-column
        label="申请人公司"
        prop="applyUserEnterprise"
        show-overflow-tooltip
      />
      <el-table-column
        label="所属展会"
        prop="meetingName"
        show-overflow-tooltip
      />
      <el-table-column label="状态" show-overflow-tooltip>
        <template #default="{ row }">
          <el-switch
            v-model="row.status"
            active-text="上架"
            inactive-text="下架"
            :active-value="true"
            :inactive-value="false"
            @change="statusChange(row)"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column label="操作" show-overflow-tooltip width="185">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">配置</el-button>
          <!-- <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button> -->
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNumber"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- <edit ref="editRef" @fetch-data="fetchData" /> -->
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
    onActivated,
    onDeactivated,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import {
    getAvertisingList,
    changeAdvertisingConfigStatus,
  } from '@/api/advert'
  export default defineComponent({
    name: 'contentManage',
    components: {
      // Edit: defineAsyncComponent(() => import('./components/edit')),
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        queryForm: {
          pageNum: 1,
          pageSize: 10,
          advertisingCode: '',
          enterpriseName: '',
          meetingName: '',
        },
        statusList: [
          {
            label: '待审核',
            key: 'PENDING_APPROVAL_STATUS',
          },
          {
            label: '审核通过',
            key: 'PASS',
          },
        ],
        total: 0,
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
        categoryList: [],
      })

      const handleEdit = (row) => {
        // state.editRef.showEdit()
        proxy.$router.push({
          path: '/advert/contentConfig',
          query: {
            advertisingCode: row.advertisingCode,
            advertisingRelationCode: row.advertisingRelationCode,
          },
        })
      }

      const handleDelete = (row) => {
        if (row.id) {
          proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteArticle({ id: row.id })
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
      }

      const queryData = () => {
        fetchData()
      }

      const fetchData = async () => {
        state.listLoading = true
        const { data } = await getAvertisingList(state.queryForm)
        if (!!data) {
          state.list = data.list
          state.total = data.total
          state.listLoading = false
        } else {
          state.list = []
          state.total = 0
          state.listLoading = false
        }
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        fetchData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNum = val
        fetchData()
      }

      onActivated(() => {
        fetchData()
        console.log('我执行了')
      })

      const statusChange = async (row) => {
        console.log(row)
        try {
          if (row.status == true) {
            await changeAdvertisingConfigStatus(row.advertisingCode, 'PUBLISH')
          } else {
            await changeAdvertisingConfigStatus(row.advertisingCode, 'OFFLINE')
          }
          fetchData()
        } catch (err) {
          console.log(err)
          fetchData()
        }
      }

      return {
        ...toRefs(state),
        handleEdit,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        Delete,
        Search,
        Plus,
        statusChange,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
